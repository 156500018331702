import { Home, Product } from '@/api/index'

export async function getNewProduct(data) {
    let res = await Home.getNewProduct(data)
    return res;
}
// 添加收藏
export async function takeCollect(data) {
    let res = await Product.takeCollect(data)
    return res;
}

export async function caclCollect(data) {
    let res = await Product.caclCollect(data)
    return res;
}