<template>
  <div class="w ww new-product">
	<div class="cp_tit" v-if="newProduct">
		<h2>{{this.$t('product')}}<span>PRODUCT</span></h2>
		<!-- <a href="#">MORE ></a> -->
		<div class="clear"></div>

	</div>
	<div class=" chanpin">
		<ul>
			<li v-for="(v,i) in newProduct" :key="i" >
          <a @click="goDetail(v.owid,v)" @contextmenu="newWindowOpen($event,v.owid,v.exp2)">
				<div class="cp_img"><img :src="pictrueUrl + v.productPic"/></div>
				<h3>{{v.productName}}</h3>
        </a>
        <div class="specsCode">{{ $t("no") }}：{{v.specsCode}}</div>
       <div class="price">
          <span :style="v.productPrice== 0?'text-decoration: none;':'text-decoration: line-through;'">{{ v.specsPromotionprice>0?v.specsForeign == 1 ? "$" : "￥" :''}}{{v.productPrice== 0 ?  $t("Inquiry"):v.productPrice || "-"}}</span>
				<div class="Promotionprice">{{v.specsPromotionprice>0? v.specsForeign == 1 ? "$" : "￥":''}}{{v.specsPromotionprice == 0? $t("Inquiry"):v.specsPromotionprice || '-'}}</div>
       </div>
        <div class="kt_list_txt_4">
              <div class="fl  kt_list_txt_6" @click="collect(v.owid,v.isCollection, i)">
                <a>
                  <img :src=" v.isCollection === 1 ? require('@/assets/img/29-1.png') : require('@/assets/img/29.png')"/>
                    {{ v.isCollection === 1 ? $t("collected") : $t("collect") }}
                  </a>
              </div>
                <div class="fl width40 kt_list_txt_7">
                  <a @click.stop="addCardItem({productRefOwid:v.owid,specsName:v.specsName,productPic:v.productPic,itemPrice:v.specsPromotionprice,categoryName:v.categoryName,categoryRefOwid:v.categoryRefOwid,specsRefOwid:v.specsRefOwid,itemQuanity:1})">
                      <img src="@/assets/img/01.png" /><span class="card-txt" >{{ $t("addCard") }}</span ></a >
                </div>
            <div class="clear"></div>
        </div>
			</li>
			<div class="clear"></div>
		</ul>
	</div>
   <Pagination :totalPage="totalPage" :currentPage="currentPage" @pageFun="goPageSrh" class="pagination"/>
</div>
</template>

<script>
import {showLayer,isHasLogin} from "@/utils/com";
import {getNewProduct,takeCollect,caclCollect} from './model'
import {addCart} from "../antibody/model";
import { IMGURL } from "@/utils/constant"
import Pagination from "@/components/component/Pagination.vue";
export default {
  data(){
    return {
      param:{
        isNew:1,
        pageNo:1,
        pageSize:8
      },
      pageNo:1,
      pageSize:8,
      newProduct:'',
      pictrueUrl:'',
      categoryRefOwid:'',
      reqParams:'',
      queryProduct:'',
      local:'',
      productPath:'',
      totalCount:0,
      currentPage:0,
      totalPage:0,
      isRecommend:this.$route.query.isRecommend,
      isAll:this.$route.query.isAll
    }
  },
  components:{
    Pagination
  },
 async created(){
    this.pictrueUrl = IMGURL;
    this.getProductList()
  },
   methods:{
    goDetail(owid,v){
       this.$router.push({
        path:'/productdetails',
        query:{proId:owid ,exp2:v.exp2}
      })
    },
    //获取产品列表
    getProductList(){
    this.reqParams = this.$route.query.params && JSON.parse(this.$route.query.params)
    // this.queryProduct = this.$route.query.search && JSON.parse(this.$route.query.search)
    if(this.$route.query.type === '1' ){
       getNewProduct({key:this.$route.query.search,pageNo:this.pageNo,pageSize:this.pageSize}).then(res=>{
         this.newProduct = res.records;
         this.totalCount = res.totalCount;
         this.currentPage = res.currentPage;
         this.totalPage = res.totalPage;
       })
      //  this.newProduct = this.queryProduct
    }else if(this.reqParams){
     	getNewProduct({attrList: this.reqParams,pageSize:this.pageSize,pageNo:this.pageNo}).then(res=>{
        this.newProduct = res.records;
         this.totalCount = res.totalCount;
         this.currentPage = res.currentPage;
         this.totalPage = res.totalPage;
			})
    }else if(this.$route.query.isRecommend === '1'){
      getNewProduct({isRecommend:this.isRecommend,pageSize:this.pageSize,pageNo:this.pageNo}).then(res=>{
        this.newProduct = res.records;
         this.totalCount = res.totalCount;
         this.currentPage = res.currentPage;
         this.totalPage = res.totalPage;
      })
    }else if(this.isAll == '1'){
      getNewProduct({pageNo:this.pageNo,pageSize:this.pageSize}).then(res=>{
        this.newProduct = res.records;
        this.totalCount = res.totalCount;
         this.currentPage = res.currentPage;
         this.totalPage = res.totalPage;
      })
    } else {
       getNewProduct(this.param).then(res =>{
        this.productPath = 1
         this.newProduct = res.records;
         this.totalCount = res.totalCount;
         this.currentPage = res.currentPage;
         this.totalPage = res.totalPage;
      })
    }
    },
    //收藏
   collect(id,isCollection,index) {
      if(isHasLogin()){
         if(isCollection !== 1){
         takeCollect({collectList:id,index}).then(res=>{
           if(res === '收藏成功'){
              this.getProductList()
             showLayer("success",res);
           }
         })
       }else{
         caclCollect({collectList: id, index}).then(res =>{
          if(res === '移除收藏成功'){
             this.getProductList()
            showLayer("success",res);
          }
         })
       }
      }else {
         showLayer("toast",'请先登录');
      }
    },
  // 加入购物车
  addCardItem(params){
     addCart(params).then(res=>{
        if(res === '添加购物车成功'){
           showLayer("success", "添加成功，在购物车等您");
        }
      })
  },
  newWindowOpen(event,owid,exp2){
     event.path[2].href = ''
    event.path[2].href = event.path[2].href+'/#/productdetails/?proId='+owid+'&'+'exp2='+exp2;

  },
  // 分页
  goPageSrh(pageNo){
   if( this.productPath==1){
      this.param.pageNo = pageNo;
    this.getProductList()
   }else{
     this.pageNo = pageNo;
     this.getProductList()
   }
  }
}

}
</script>

<style scoped>
  .new-product {
    margin-top: .2rem;
    margin-bottom: .2rem;
  }
  .cp_tit {
    position: relative;
  }
  .cp_tit h2 span {
    font-size: .09rem;
    color: rgba(95,153,172,.3);
    margin-left: .03rem;
  }
  .cp_tit a {
    position: absolute;
    top: 10px;
    right: 0;
    display: inline-block;
     font-size: 14px;
    color: #999;
 }
 .chanpin .specsCode {
   margin-top: .05rem;
   color: #999;
 }
 .price {
   color: #999;
   margin-top: 0.05rem;
 }
 .price span {
   text-decoration: line-through;
 }
 .Promotionprice {
   margin-left: 0.6rem;
   color: #f55050;
   text-decoration:none;
   display: inline-block;
 }
</style>
