/*
 * @Description:
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-10 15:00:44
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-19 10:56:07
 */
import { Product, Com , Home } from '@/api/index';
import { dealUrlHttp, showLayer, isHasLogin } from '@/utils/com';

//分类下查询条件
export async function getNorms(data) {
  let res = await Product.getNorms(data);
  if (res && Array.isArray(res)) {
    let newArray = [];
    res.forEach((v) => {
      let arr = [];
      if (v.value) {
        arr = v.value.split(',');
        arr = arr.map((v) => {
          return {
            val: v,
            isSelect: false,
          };
        });
      }
      newArray.push({
        name: v.name,
        code:v.code,
        values: arr,
      });
    });
    return newArray;
  }
}

//获取广告
export async function getAdv(data) {
  let res = await Com.getAdv(data);
  if (res && Array.isArray(res)) {
    return res.map((v) => {
      v.url = dealUrlHttp(v.url);
      return v;
    });
  }
}

//产品推荐
export async function rdmPro(data) {
  let res = await Product.rmdProducts(data);
  if (res && res.records && Array.isArray(res.records)) {
    res.records.forEach((e) => {
      e.productPic = dealUrlHttp(e.productPic);
    });
    return res;
  }
}


//多条件搜索 转成规定的对象数组
export function handleObj2Arr(data){
  let allArr = [];
  let obj = {};
  for (let i in data)
  {
    let arr = data[i].split(',');
    for (let j = 0; j < arr.length; j++)
    {
      if (arr[j])
      {
        obj = {
          attrCode: i,
          attrValue: arr[j]
        }
        allArr.push(obj);
      }
    }
  }
  return allArr
}

// 加入购物车
export async function addCart(data){
  let res = await Product.addCart(data)
  return res;
}

export async function filterTreeList(data){
  let res = await Product.filterTreeList(data)
  return res;
}

export async function getNewProduct(data){
  let res = await Home.getNewProduct(data)
  return res;
}