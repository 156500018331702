/*
 * @Description: 定义菜单等常量
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-20 10:25:29
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-20 16:26:34
 */
const LEFTMENU = [
    { label: '我的订单', url: '/profile/order' },
    { label: '我的购物车', url: '/shoppingcart', childUrl: '/profile/sureCart' },
    { label: '我的收藏', url: '/profile/collect' },
    { label: '我的足迹', url: '/profile/myTracks' },
    {
        label: '我的发票',
        url: '/profile/control',
        childs: true,
        children: [
            { label: '开票信息', url: '/profile/invoice' },
            { label: '发票管理', url: '/profile/control' }
        ]
    },
];

const IMGURL = process.env.VUE_APP_IMG_PATH


export {
    LEFTMENU,
    IMGURL
}