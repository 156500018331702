<!--
 * @Description: 分页器
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-18 14:40:34
 * @LastEditors: zmj
 * @LastEditTime: 2022-03-21 11:34:27
-->
<template>
  <div class="pages" @click="goPage">
    <a  data-page="pre" v-if="currentPage>1">&lt;</a>
    <a :data-page="v" :class="{on:currentPage==v}" v-for="(v,index) in pages" :key="index">{{v}}</a>
    <a data-page="after" v-if="currentPage<pages.length">&gt;</a>
    {{this.$t('total')}} {{totalPage}} {{this.$t('page')}}
    <div class="pages_1">
      <span>{{this.$t('jumpTo')}}</span>
      <input v-model="goPageVal" name="" type="text" />
    </div>
    <input name="" data-page="sure" type="button" :value="this.$t('determine')" />
  </div>
</template>

<script>
export default {
  props: {
    totalPage: {
      type: Number,
      default: 1
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data(){
    return{
      goPageVal:''
    }
  },
  computed:{
    pages(){
      let pages = [];
      for(let i=1;i<this.totalPage+1;i++){
        pages.push(i)
      }
      return pages
    }
  },
  methods:{
    goPage(e){
      let pageNum = e.target.dataset.page;
      if(pageNum=="sure"&&this.goPageVal){
        if(this.goPageVal<0||this.goPageVal>this.totalPage){
          return;
        }else{
           pageNum = this.goPageVal
        }
      }
      if(pageNum=="pre"){
        pageNum = this.currentPage-1
      }
       if(pageNum=="after"){
        pageNum = this.currentPage+1
      }
      pageNum&&this.$emit("pageFun",pageNum);
    }
  }
}
</script>

<style>
</style>